
.Screen{
    background-color: red;
    width: 100%;
    min-height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
}

.ScreenLogo{
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 150px;
    max-width: 50%;
    display: block;
}

