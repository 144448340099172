*:focus,*:active {
  outline: transparent none;
}
body {
  margin: 0;
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  user-select: none;
}
html{
  height: 100%;
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
  padding: env(safe-area-inset);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root{
  height: 100%
}
a{
  color:#F1696B;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-decoration: none;
  letter-spacing: -0.5px;
}

@font-face {
  font-family: 'ico-kids';
  src: url('assets/fonts/ico-kids.eot');
  src: url('assets/fonts/ico-kids.eot?#iefix') format('embedded-opentype'),
    /*url('assets/fonts/ico-kids.woff2') format('woff2'),*/
  url('assets/fonts/ico-kids.woff') format('woff'),
  url('assets/fonts/ico-kids.ttf') format('truetype'),
  url('assets/fonts/ico-kids.svg#ico-kids') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GochiHand';
  src: url('assets/fonts/GochiHand-Regular.eot');
  src: url('assets/fonts/GochiHand-Regular.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/GochiHand-Regular.svg#GochiHand-Regular') format('svg'),
  url('assets/fonts/GochiHand-Regular.ttf') format('truetype'),
  url('assets/fonts/GochiHand-Regular.woff') format('woff'),
  url('assets/fonts/GochiHand-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}



@font-face {
  font-family: 'Helvetica Neue';
  src: url('assets/fonts/HelveticaNeue.eot');
  src: local('Helvetica Neue'), local('HelveticaNeue'),
  url('assets/fonts/HelveticaNeue.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/HelveticaNeue.woff2') format('woff2'),
  url('assets/fonts/HelveticaNeue.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
.GameIframe{
  position: absolute;
  z-index: 90;
  top: 0;
  left: 0;
}
.slick-center .favorite-button{
  bottom: -15px;
}
.slick-center .video-image{
  transform: scale(1.2);
  transition: all 300ms ease;
}
.slick-center .video-image-reply{
  margin-top: 0;
}

.video-image-reply{
  position: absolute !important;
}
.slick-list{
  overflow: visible !important;
}
.switch-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
  height: 100%;
}
.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
input[type=number] {
  -webkit-text-security: disc;
}

.icons{
  font-family: ico-kids;
  position:relative;
  font-size: 29px;
  top:1px;
  display:inline-block;
  font-style:normal;
  font-weight:400;
  vertical-align: middle;
  line-height: inherit;
}

.centered{
  display: block;
  text-align: center;
}

.icon-share-settings:before {
  content: '\e91b';
  padding-right: 15px;
}

.icon-profile:before {
  content: '\e915';
}

.icon-game-cat1:before {
  content: '\e907';
}
.icon-game-cat2:before {
  content: '\e90b';
}
.icon-game-cat3:before {
  content: '\e908';
}
.icon-game-cat4:before {
  content: '\e909';
}
.icon-game-cat5:before {
  content: '\e90a';
}
.icon-game-cat6:before {
  content: '\e906';
}

.icon-features:before{
  content: "\e902";
}
.selected-section .icon-features:before{
  color: #E25A38;
}
.icon-games:before{
  content: "\e903";
}
.selected-section .icon-games:before{
  color: #3F085D;
}
.icon-videos:before{
  content: "\e904";
}
.icon-stories:before{
  content: "";
  width: 32px;
  height: 32px;
  display: block;
}
.return-popup .icon-stories:before {
  content: "";
  width: 72px;
  height: 72px;
  background-size: contain;
  display: block;
  margin-left: calc(50% - 36px);
}
.selected-section .icon-videos:before{
  color: #39ADA2;
}
.selected-section .icon-stories:before{
  color: #39ADA2;
}

.icon-favorites:before{
  content: "\e900";
}

.icon-home:before{
  content: "\e917";
}

.icon-arrow-back:before{
  content: "\e90e";
}

.selected-section .icon-favorites:before{
  color: #F1696B;
}

.icon-slim-arrow-right:before{
  content: "\e911";
}

.icon-plus:before{
  content: "\e90f";
}
.icon-settings:before{
  content: "\e913";
}

.icon-fav-active:before{
  content: "\e900";
  color: #F1696B;
}

.icon-fav-inactive:before{
  content: "\e901";
}

.icon-download:before{
  content: "\e916";
}

.icon-checkmark:before{
  content: "\e910";
}

.icon-checkbox-on:before{
  content: '\e919';
}

.icon-checkbox-partial:before{
  content: '\e91a';
}

.icon-checkbox-off:before{
  content: '\e918';
}

.icon-remove:before{
  content: '\e912';
}

.icon-eye:before{
  content: '\e914';
}
.icon-orientation:before{
  content: '\e91e';
}

.icon-exit:before{
  content: '\e921';
}

.icon-share:before{
  content: '\e925';
}
.icon-cat-words:before {
  content: "\e906";
}
.icon-cat-heroes:before {
  content: "\e91d";
}
.icon-comptines:before {
  content: "";
  width: 32px;
  height: 32px;
  background-size: contain;
  display: block;
  position: absolute;
  top: -13px;
  left: -15px;
  content: "\e905";
}
.selected-section .icon-comptines:before {
  color: #4B6AAB;
}
.return-popup .icon-comptines:before {
  width: 72px;
  height: 72px;
  position: static;
  content: "\e905";
  display: inline;
}
.content-access .icon-comptines:before {
  width: 72px;
  height: 72px;
  position: static;
  content: "\e905";
  display: inline;
}
.fav-cat .icon-comptines:before {
  top: -20px;
  left: -20px;
}
.icon-stories:before{
  background-image: url(assets/images/icon-cat-comptine.svg);
}
.selected-section .icon-stories:before{
  background-image: url(assets/images/icon-cat-comptine-sel.svg);
}
.icon-comptines-rose:before {
  background-image: url(assets/images/icon-cat-comptine-rose.svg);
  width: 28px;
  height: 28px;
  top: -16px;
  left: 5px;
}
.icon-stories-rose:before {
  background-image: url(assets/images/icon-cat-comptine-rose.svg);
  width: 28px;
  height: 28px;
  top: -16px;
  left: 5px;
}
.icon-game-casual:before {
  content: "\e924";
}
.icon-cat-featured:before {
  content: "\e902";
}

.icon-cancel-download:before {
  content: "\e91f";
}

.icon-tips:before {
  content: '\e91c';
}
.slick-slider{
  margin: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media screen and (max-height: 375px) {
  .slick-center .favorite-button{
    bottom: 17px;
  }
  .video-image-reply{
    width: 110px;
    height: 105px !important;
    margin-top: 17px !important;
  }
}
.videoSubcatSlider {
  top: 0;
  transform: none;
}
.video-image-reply{
  background-size: 50px 50px;
  background-position: center center;
  z-index: 111;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  width: 140px;
  height: 133px;
  top: 0;
}


.player .current-song .time {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 240px;
}
.player .current-song #timeline {
  position: relative;
  margin: 0 auto;
  width: 240px;
  height: 8px;
  background: #709fdc;
  border-radius: 5px;
  cursor: pointer;
}
.player .current-song #timeline:hover .hover-playhead {
  opacity: 1;
}
.player .current-song #timeline:hover .hover-playhead::before {
  opacity: 1;
}
.player .current-song #timeline:hover .hover-playhead::after {
  opacity: 1;
}
.player .current-song #timeline #playhead {
  position: relative;
  z-index: 2;
  width: 0;
  height: 8px;
  border-radius: 5px;
  background: #071739;
}
.player .current-song #timeline .hover-playhead {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 0;
  height: 8px;
  opacity: 0;
  border-radius: 5px;
  background: #274684;
  transition: opacity 0.3s;
}
.player .current-song #timeline .hover-playhead::before {
  opacity: 0;
  content: attr(data-content);
  display: block;
  position: absolute;
  top: -30px;
  right: -23px;
  width: 40px;
  padding: 3px;
  text-align: center;
  color: white;
  background: #274684;
  border-radius: calc( 20px - 12px);
}
.player .current-song #timeline .hover-playhead::after {
  opacity: 0;
  content: "";
  display: block;
  position: absolute;
  top: -8px;
  right: -8px;
  border-top: 8px solid #274684;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}
.player .current-song .controls {
  margin-top: 10px;
}
.player .current-song .controls button {
  color: #071739;
  border-radius: 50%;
  margin: 15px;
  font-size: 18px;
  text-align: center;
  transition: 0.2s;
  cursor: pointer;
  border: none;
  background: 0;
}
.playerH .current-song .controls button {
  margin: 4px 15px;
}
.player .current-song .controls button:focus {
  outline: none;
}
.player .current-song .controls button.play {
  width: 50px;
  height: 50px;
  border: 1px solid #e2e2e2;
}
.player .current-song .controls button.play:hover {
  left: 0;
  box-shadow: 0px 0px 15px 0px rgba(39, 70, 132, 0.7);
}
.player .current-song .controls button.play .fa-play {
  transform: translateX(2px);
}
.player .current-song .controls button.prev-next {
  width: 35px;
  height: 35px;
}
.player .current-song .controls button.prev-next:hover {
  transform: scale(1.2);
}
.player .play-list {
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 180px;
  overflow-y: scroll;
}
.player .play-list .track {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-radius: calc( 20px - 10px);
  border: 1px solid transparent;
  transition: 0.3s;
  cursor: pointer;
}
.player .play-list .track:hover {
  background: #274684;
  border-color: #274684;
  position: relative;
}
.player .play-list .track.current-audio {
  background: #274684;
  box-shadow: 0px 0px 15px 0px #274684;
}
.player .play-list .track.play-now {
  background: #274684;
  box-shadow: 0px 0px 15px 0px #274684;
  position: relative;
}
.player .play-list .track.play-now:after {
  content: "";
  display: block;
  position: absolute;
  left: 17px;
  width: 57px;
  height: 57px;
  border-radius: calc( 20px - 10px);
  font-size: 16px;
  -webkit-animation: play 2s linear infinite;
  animation: play 2s linear infinite;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 56 56' style='enable-background:new 0 0 56 56;' xml:space='preserve'%3E%3Cpath style='fill:%23071739;' d='M47.799,8.201c-10.935-10.935-28.663-10.935-39.598,0c-10.935,10.935-10.935,28.663,0,39.598 c10.935,10.935,28.663,10.935,39.598,0C58.734,36.864,58.734,19.136,47.799,8.201z M32.95,32.95c-2.734,2.734-7.166,2.734-9.899,0 c-2.734-2.734-2.734-7.166,0-9.899s7.166-2.734,9.899,0S35.683,30.216,32.95,32.95z'/%3E%3Cpath style='fill:%23E7ECED;' d='M35.778,20.222c-4.296-4.296-11.261-4.296-15.556,0c-4.296,4.296-4.296,11.261,0,15.556 c4.296,4.296,11.261,4.296,15.556,0C40.074,31.482,40.074,24.518,35.778,20.222z M30.121,30.121c-1.172,1.172-3.071,1.172-4.243,0 s-1.172-3.071,0-4.243s3.071-1.172,4.243,0S31.293,28.95,30.121,30.121z'/%3E%3Cg%3E%3Cpath style='fill:%23709fdc;' d='M35.778,35.778c-0.76,0.76-1.607,1.378-2.504,1.87l8.157,14.92c2.284-1.25,4.434-2.835,6.368-4.769 c1.934-1.934,3.519-4.084,4.769-6.368l-14.92-8.157C37.157,34.172,36.538,35.018,35.778,35.778z'/%3E%3Cpath style='fill:%23709fdc;' d='M20.222,20.222c0.76-0.76,1.607-1.378,2.504-1.87l-8.157-14.92c-2.284,1.25-4.434,2.835-6.368,4.769 s-3.519,4.084-4.769,6.368l14.92,8.157C18.843,21.828,19.462,20.982,20.222,20.222z'/%3E%3C/g%3E%3C/svg%3E");
}
.player .play-list .track.play-now .track-img {
  filter: opacity(70%);
}
.player .play-list .track .track-img {
  width: 90px;
  border-radius: calc( 20px - 10px);
}
.player .play-list .track .track-discr {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  min-width: 190px;
}
.player .play-list .track .track-discr .track-name {
  font-size: 17px;
  margin-top: 8px;
}
.player .play-list .track .track-discr .track-author {
  margin-top: 8px;
  font-weight: 300;
  color: #709fdc;
}
.player .play-list .track .track-duration {
  min-width: 40px;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 500;
}


.play-list::-webkit-scrollbar {
  width: 5px;
}

.play-list::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 5px;
}

.play-list::-webkit-scrollbar-track {
  background: #071739;
}

@-webkit-keyframes play {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes play {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#welcome .slide-text {
  position: fixed;
  bottom: -75px;
  width: 100%;
  padding: 20px;
  background-color: rgba(0,0,0,0.5);
  box-sizing: border-box;
}