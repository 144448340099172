.Modal {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 90%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    max-width: 320px;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    border-radius: 5px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.Modal img{
    display: block;
    margin: auto;
    width: 90px;
    margin-top: 20px;
}
.ModalTitle{
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.5px;
    color: #000;
    text-align: center;
    margin-top: 20px;
    padding:0 16px;
}
.ModalFooter{
    border-top: 1px solid lightgray;
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    align-items: center;
    margin-top: 10px;
}
.ModalFooter div{
    text-align: center;
    font-size: 16px;
    padding: 15px;
}
.CancelButton{
    color: #000000;
    border-right: 1px solid lightgray;
}
.ActionButton{
    font-weight: bold;
    color: #F1696B;
}
.ModalBody{
    font-size: 16px;
    letter-spacing: -0.5px;
    text-align: center;
    padding:0 16px;
    line-height: 20px;
}
.ModalBody a{
    text-decoration: underline;
}
.closeModal{
    float: right;
    font-size: 23px;
    color: grey;
    margin-top: -11px;
}
.modalHeader{
    height: 40px;
    padding: 11px 24px;
    margin-bottom: -20px;
}
.modalHeader img{
    width: 33px;
    margin: 0;
    float: left;
}