h1{
    color:black;
}
label{
    color: #F1696B;
    font-size: 14px;
    font-weight: 500;
}
input::placeholder{
    color: rgba(72, 43, 88, 0.3);
}
.ParentZoneIndex{
    background-color: #3F085D;
    background-image: url(../../assets/images/bg-stars.svg);
    background-image: linear-gradient(180deg, rgba(0,0,0,0) 40%, rgba(51,28,63,1) 100%) , url(../../assets/images/bg-stars.svg);
    background-size: cover;
    width: 100%;
    min-height: 100%;
    position: absolute;
    padding-bottom: 60px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-origin: border-box;
}
.LogoImg{
    position: absolute;
    left: calc(50% - 41px);
    top: 16px;
    width: 92px;
}
.KidName{
     font-family: Montserrat;
     font-size: 18px;
     font-weight: bold;
     line-height: 22px;
     text-align: center;
     color: white;
 }

.AccessControls{
    font-family: Montserrat;
    font-size: 16px;
    text-align: left;
    color: #3F085D;
    flex-grow: 1;
    line-height: 18px;
}
.AccessControlsImage{
    color: #F1696B;
    font-size: 26px;
    margin-right: 15px;
    float: left;
    width: 40px;
    text-align: center;
}
.AddKidProfile{
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -56px;
    width: 112px;
    font-size: 56px;
    line-height: 56px;
    height: 56px;
    background-color: #F1696B;
    border-top-left-radius: 56px;
    border-top-right-radius: 56px;
}
.HasNoProfiles{
    position: absolute;
    bottom: auto;
    top: calc(50% - 75px);
    left: calc(50% - 75px);
    line-height: 150px;
    font-size: 58px;
    width: 150px;
    border-radius: 75px;
    height: 150px;
    background-color: #F1696B;
}

.NoProfileText{
    color: white;
    width: 100%;
    text-align: center;
}
.KidProfile img{
    width: 100%;
}
.KidAvatar{

}
.IconSettings{
    position: absolute;
    right: 20px;
    top: 20px
}
.BackLink{
    width: 100%;
    color: #F1696B;
    position: relative;
    top: 0;
    font-size: 20px;
    left: 0;
    border-bottom: 1px solid rgba(72, 43, 88, 0.2);
    height: 60px;
    line-height: 60px;
}
.PageTitle{
   color: #000000;
   font-size: 16px;
    font-weight: 500;
    line-height: 61px;
    text-align: center;
    width: 100%;
}
.SelectAvatar{
    height: 150px;
    width: 150px;
    background-color: #F1696B;
    border-radius: 75px;
    margin: 25px auto;
    margin-bottom: 30px;
    color: white;
    text-align: center;
    line-height: 150px;
}
.SelectAvatar img{
    width: 100%;
}
.BackArrow{
    position: absolute;
    top: 0;
    left: 24px;
}
.AvatarsList{
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    margin-top: 12px;
    flex-wrap: wrap;
}
.AvatarImage{
    width: 170px;
    height: 170px;
    text-align: center;
    margin-top: 15px;
}
.AvatarImage img{
    width: 90%;
}
.PickerSliders{
    margin: 10px auto;
    width: 90%;
    margin-bottom: 20px;
}

.WeekDaySelectorPinned{
    background-color: white;
    margin-top: 15px;
}


.WeekDaySelector{
    width: 90%;
    margin: auto;
    margin-bottom: 20px;
    height: 36px;
    line-height: 36px;
    border-radius: 25px;
    border: 1px solid rgba(72, 43, 88, 0.3);
    text-align: center;
    color: rgba(72, 43, 88, 0.3);
}
.WeekDaySelector div{
    float: left;
    width: 50%;

}
.WeekDaySelector div:first-child{
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
}
.WeekDaySelector div:last-child{
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
}
.ActiveTab{
    background-color: #F1696B;
    color:white;
}
.SliderLabel{
    margin-top: 35px;
    color: #3F085D;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    margin-bottom: 10px;
}
.SettingsLine{
    height: 65px;
    line-height: 65px;
    color:#3F085D;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    margin: auto;
    border-bottom: 1px solid rgba(63, 8, 93, 0.05);
}
.SettingsLine:first-child{
    border-top: 1px solid rgba(63, 8, 93, 0.05);
    margin-top: 50px;
}
.SettingsLine img{
    width: 35px;
    margin-right: 22px;
}
.SettingsLine svg{
    color:#F1696B;
    margin-top: 25px;
}
.SectionTitle{
    height: 65px;
    width: 100%;
    background-color: rgba(63, 8, 93, 0.05);
    color: rgba(63, 8, 93, 0.5);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 90px;
    box-sizing: border-box;
    text-transform: uppercase;
    padding-left: 16px;
}

.SectionTitleWithImage{
    height: 65px;
    line-height: 65px;
    background-color: rgba(63, 8, 93, 0.05);
    color: #F1696B;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    margin: auto;
    border-bottom: 1px solid rgba(63, 8, 93, 0.05);
    font-weight: 600;
    text-transform: uppercase;
}

.SectionTitlePinned{
    background-color: rgba(63, 8, 93, 0.32) !important;
    color: white;
}

.AccessControlsGameImage{
    width: 45px !important;
    height: 45px !important;
    border-radius: 5px;
    margin-top: 8px;
}
.KidsProfilesList{
    width: 90%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 15px
}
.KidProfile{
    width: 150px;
}
.justOneKidProfile{
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}
.moreThenOneKidProfile{
    margin-top: 90px;
}

.moreThenOneKidProfile:nth-child(2n+1){
    margin-top: 20px;
}

.lastKidProfile{
    margin-right: auto;
    margin-left: 20px;
}

.WhoIsPlaying{
    text-align: center;
    color: white;
    font-size: 24px;
    font-family: Montserrat;
    margin-top: 40px;
}
.ParentZoneTopBar{
    height: 70px;
    width: 100%;
}
.splashScreen{
    width: 100%;
    height: 100%;
    position: fixed;
    text-align: center;
    box-sizing: border-box;
    padding: 10px;
    padding-top: 25%;
    font-size: 20px;
    color: black;
    background-color: white;
    display: none;
    z-index: 99;
}
@media screen and (orientation: landscape) {
    .WhoIsPlaying {
        margin-top: -40px;
    }
    .moreThenOneKidProfile{
        margin-top: 20px;
    }
    .KidsProfilesList{
        margin: auto;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    .splashScreen{
        display: block;
    }
}
@media screen and (max-width: 340px) {
    .KidProfile{
        width: 130px;
    }
    .AvatarImage{
        width: 150px;
        height: 150px;
    }
}