
.SplashScreen{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    text-align: center;
    color: white;
    background-color: rgba(38,18,48,0.95);
    z-index: 999;
    display: none;
}

.SplashScreenContent{
    width: 200px;
    height: 200px;
    position:absolute;
    top:50%;
    left:50%;
    margin-left:-100px;/* half width*/
    margin-top:-100px;/* half height*/
}


@media screen and (orientation: portrait) {
    .SplashScreen.Portrait{
        display: block;
    }


}
@media screen and (orientation: landscape) {
    .SplashScreen.Landscape{
        display: block;
    }
}