.BackLink{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #F1696B;
    font-size: 20px;
    border-bottom: 1px solid rgba(72, 43, 88, 0.2);
    height: 60px;
    box-sizing: border-box;
}
.PageTitle{
    color: #000000;
    font-size: 16px;
    font-weight: 500;
}

.PageTitleLong{
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    flex: 2;
}

.BackArrow{
    margin-left: 10px;
}
.RightButton{
    color: #F1696B;
    font-size: 24px;
    margin-right: 10px;
}