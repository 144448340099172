.Keyboard{
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    margin: 25px auto;
    flex-wrap: wrap;
    width: 90%;
    max-width: 350px;
    align-content: space-between;
    height: 400px;
}
.Keyboard div{
    background-color: #F3F7FE;
    width: 90px;
    height: 90px;
    border-radius: 45px;
    color: #3F085D;
    font-family: Montserrat;
    font-size: 18px;
    text-align: center;
    line-height: 90px;
}
.PageTitle{
    width: 100%;
    color: #F1696B;
    font-family: Montserrat;
    font-size: 18px;
    text-align: center;
}
.BackLink{
    color: #F1696B;
    position: relative;
    top: 0;
    font-size: 20px;
    left: 0;
    height: 60px;
    line-height: 60px;
    padding-left: 20px;
}
.Dots{
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    width: 100px;
    margin: 10px auto;
}
.Dots div{
    height: 14px;
    width: 14px;
    background-color: #E7EDF6;
    border-radius: 7px;
}
.DotFilled{
    background-color: #F1696B !important;
}
.Footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    position: absolute;
    bottom: 30px;
    left: 5%;
}
.ForgotPin{
    color: #F1696B;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
}
.DeleteButton{
    color: #3F085D;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
}
.PinVerificationError{
    color: red;
    width: 90%;
    left: 5%;
    text-align: center;
    font-size: 12px;
    position: absolute;
    top:30px;
}
.splashScreen{
    width: 100%;
    height: 100%;
    position: fixed;
    text-align: center;
    box-sizing: border-box;
    padding: 10px;
    padding-top: 25%;
    font-size: 20px;
    color: black;
    background-color: white;
    display: none;
    z-index: 99;
}
@media (orientation: landscape) {
    .Footer {
        position: relative;
        margin-top: 25px;
    }
    .splashScreen{
        display: block;
    }
    body{
        overflow: hidden;
    }
}