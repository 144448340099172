.Button {
    background-color: #F1696B;
    color: white;
    outline: none;
    cursor: pointer;
    font: inherit;
    margin: 15px auto;
    border-radius: 37px;
    border: none;
    padding: 12px;
    text-align: center;
    width: 90%;
    display: block;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 600;
}
.Success {

}

.Danger {
    color: #944317;
}
.Button:disabled{
    color: gray;
    cursor: not-allowed;
}
.StickToBottom{
    position: absolute;
    bottom: 20px;
    left: 5%;
}
@media screen and (min-width: 1200px) {
    .Button {
        max-width: 328px;
        height: 50px;
        line-height: 50px;
        padding: 0;

    }
    .StickToBottom{
        left: calc(50% - 164px);
    }
}