.Content{
    margin:0;
    height: 100%;
    position: absolute;
    width: 100%;
    min-height: 320px;
}
@media screen and (orientation: portrait) {
    .Content{
        min-height: 480px;
    }
}