
.ErrorScreen {
    background-color: #3F085D;
    background-image: url(../../assets/images/bg-stars.svg);
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, rgba(51, 28, 63, 1) 100%), url(../../assets/images/bg-stars.svg);
    background-size: cover;
    width: 100%;
    min-height: 100%;
    position: absolute;
    padding-bottom: 60px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-origin: border-box;
    font-family: Montserrat;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    letter-spacing: -0.5px;
}


.ErrorMessage {
    position: absolute;
    height: 100px;
    width: 300px;
    left: 50%;
    margin-left: -150px;
    top: 50%;
    margin-top: -50px;
    font-size: 16px;
    line-height: 20px;
}


.RestartTitle {
    width: 254px;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
}