.WelcomeScreen{
    background-color: #482b58;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}
h1{
    color:white;
    font-size: 35px;
    font-weight: 600;
    letter-spacing: -0.5px;
    line-height: 40px;
    margin:16px;
    font-family: Montserrat;
}
.LogoImg{
    position: absolute;
    left: calc(50% - 57px);
    top: 32px;
}
.WelcomeBg{
    width: 100%;
}

.SafePlace{
    position: absolute;
    bottom: 70px;
    color:white;
}
.appIcon{
    width: 56px;
    height: 56px;
    position: absolute;
    top:13px;
    left: calc(50% - 28px);
}
.DesktopWelcomeDescription{
    width: 565px;
    opacity: 0.7;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 17px;
    letter-spacing: -0.21px;
    line-height: 20px;
    text-align: center;
    display: none;
    position: absolute;
    bottom: 105px;
}
@media screen and (min-width: 1200px) {
    .DesktopWelcomeDescription{
        display: block;
        left: calc(50% - 283px);
    }
    .SafePlace{
        width: 100%;
        text-align: center;
        bottom: 145px;
    }
}