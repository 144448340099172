.Loader, .LoaderBlue, .LoaderRed {
    display: inline-block;
    width: 16px;
    height: 16px;
}
.LoaderRed {
    width: 22px;
    height: 22px;
}
.Loader:after, .LoaderBlue:after , .LoaderRed:after{
    content: " ";
    display: block;
    width: 16px;
    height: 16px;
    margin: 1px;
    border-radius: 50%;
    border: 3px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
.LoaderBlue:after{
    border: 3px solid #3F085D;
    border-color: #3F085D transparent #3F085D transparent;
}

.LoaderRed:after{
    border: 3px solid #F1696B;
    border-color: #F1696B transparent #F1696B transparent;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
